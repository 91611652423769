
import { defineComponent } from 'vue'
import ProgressSpinner from 'primevue/progressspinner'
import Divider from 'primevue/divider'

export default defineComponent({
  props: [`codes`, `onSyncCode`, `syncing`, `onDeleteCode`],
  components: {
    ProgressSpinner,
    Divider,
  },
})
